import axios, { AxiosInstance, AxiosResponse } from 'axios';

// Types
export interface TimeRange {
  startTime: string;
  endTime: string;
}

export interface CalendarConfig {
  timezone: string;
  partiesConfig: {
    [key: string]: {
      label: string;
      availableDays: {
        [key: string]: {
          startTime: string;
          endTime: string;
        }[];
      };
      price: number;
    };
  };
  customOptions: {
    partyPackages: string[];
  };
  addons: {
    [key: string]: {
      [key: string]: {
        options: {
          order: number;
          options: {
            label: string;
            price: number;
            descripiton: string[];
          }[] | string[];
        }[];
        label: string;
      };
    };
  };
}

export interface BookedEvent {
  start: {
    dateTime: string;
    timeZone: string;
  };
  end: {
    dateTime: string;
    timeZone: string;
  };
}

export interface CalendarResponse {
  events: BookedEvent[];
  config: CalendarConfig;
  addons: any;
}

export interface RequestSlotData {
  date: string;
  startTime: string;
  endTime: string;
  appointmentId?: string;
  partyType: string;
}

export interface RequestSlotResponse {
  status: 'success' | 'error';
  appointmentId?: string;
  expiresAt?: {
    _seconds: number;
    _nanoseconds: number;
  };
  message?: string;
}

export interface HeartbeatResponse {
  status: 'success' | 'error';
  message?: string;
}

export interface PaymentData {
  squareOrderId: any;
  amount: number;
}

export interface ConfirmAppointmentData {
  appointmentId: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  notes?: string;
  paymentData: PaymentData;
}

export interface ConfirmAppointmentResponse {
  status: 'success' | 'error';
  message: string;
}

// API Client class
class ApiClient {
  private client: AxiosInstance;
  private heartbeatInterval: NodeJS.Timeout | null = null;

  constructor() {
    this.client = axios.create({
      baseURL: import.meta.env.VITE_NEWPLAY_API_BASE_URL || '', // Make sure to add this to your .env
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Add response interceptor for error handling
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handle specific error cases here
        if (error.response?.status === 401) {
          // Handle unauthorized
          console.error('Unauthorized access');
        }
        return Promise.reject(error);
      }
    );
  }

  // Calendar endpoints
  public async getUpcomingEvents(): Promise<CalendarResponse> {
    try {
      const response: AxiosResponse<CalendarResponse> = await this.client.get('/calendar/upcoming');
      return response.data;
    } catch (error) {
      console.error('Failed to fetch calendar events:', error);
      throw error;
    }
  }

  public async requestTimeSlot(data: RequestSlotData): Promise<RequestSlotResponse> {
    try {
      const response = await this.client.post('/calendar/request-slot', data);

      // If successful, start the heartbeat
      if (response.data.status === 'success' && response.data.appointmentId) {
        this.startHeartbeat(response.data.appointmentId);
      }

      return response.data;
    } catch (error) {
      console.error('Failed to request time slot:', error);
      throw error;
    }
  }

  public async sendHeartbeat(appointmentId: string): Promise<HeartbeatResponse> {
    try {
      const response = await this.client.post('/calendar/appointment/heartbeat', {
        token: appointmentId
      });
      return response.data;
    } catch (error) {
      console.error('Failed to send heartbeat:', error);
      throw error;
    }
  }

  public startHeartbeat(appointmentId: string) {
    // Clear any existing interval
    this.stopHeartbeat();

    // Start new interval
    this.heartbeatInterval = setInterval(async () => {
      try {
        await this.sendHeartbeat(appointmentId);
      } catch (error) {
        console.error('Heartbeat failed:', error);
        // Optionally handle failed heartbeat (e.g., show warning to user)
      }
    }, 5 * 60 * 1000); // 5 minutes
  }

  public stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  public async confirmAppointment(data: ConfirmAppointmentData): Promise<ConfirmAppointmentResponse> {
    try {
      const response = await this.client.post('/calendar/appointment/confirm', data);
      return response.data;
    } catch (error) {
      console.error('Failed to confirm appointment:', error);
      throw error;
    }
  }

  // Add more API methods here as needed
  // For example:
  // public async createBooking(bookingData: BookingData): Promise<BookingResponse> {
  //   const response = await this.client.post('/bookings', bookingData);
  //   return response.data;
  // }
}

// Create and export a single instance
export const apiClient = new ApiClient();

// Export a convenience method for calendar
export const getCalendarEvents = () => apiClient.getUpcomingEvents();

export const requestTimeSlot = (data: RequestSlotData) => apiClient.requestTimeSlot(data);

// Export heartbeat methods
export const startHeartbeat = (appointmentId: string) => apiClient.startHeartbeat(appointmentId);
export const stopHeartbeat = () => apiClient.stopHeartbeat();

// Export the confirmation method
export const confirmAppointment = (data: ConfirmAppointmentData) => apiClient.confirmAppointment(data);
