import React from 'react';
import { UserDetails, ValidationErrors } from './types';

interface PersonalDetailsProps {
  userInfo: UserDetails;
  errors: ValidationErrors;
  isProcessing: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onInputBlur: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onNext: () => void;
  customOptions: {
    [key: string]: any;
  };
}

export const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  userInfo,
  errors,
  isProcessing,
  onInputChange,
  onInputBlur,
  onNext,
  customOptions
}) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card border-0">
            <h3 className="text-center mb-4">Enter Your Details</h3>

            <div className="mb-3">
              <label htmlFor="name" className="form-label">Full Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={userInfo.name}
                onChange={onInputChange}
                onBlur={onInputBlur}
                required
                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={userInfo.email}
                onChange={onInputChange}
                onBlur={onInputBlur}
                required
                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone Number *</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={userInfo.phone}
                onChange={onInputChange}
                onBlur={onInputBlur}
                placeholder="(555) 555-5555"
                required
                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
              />
              {errors.phone && (
                <div className="invalid-feedback">{errors.phone}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="partyPackage" className="form-label">Party Package *</label>
              <select
                id="partyPackage"
                name="partyPackage"
                value={userInfo.partyPackage || ''}
                onChange={onInputChange}
                onBlur={onInputBlur}
                required
                className={`form-control ${errors.partyPackage ? 'is-invalid' : ''}`}
              >
                <option value="">Choose a package</option>
                {customOptions.partyPackages && (customOptions.partyPackages as string[]).map((pkg: string) => (
                  <option key={pkg} value={pkg}>
                    {pkg.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                  </option>
                ))}
                <option value="none">None</option>
              </select>
              {errors.partyPackage && (
                <div className="invalid-feedback">{errors.partyPackage}</div>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="notes" className="form-label">Additional Notes</label>
              <textarea
                id="notes"
                name="notes"
                value={userInfo.notes}
                onChange={onInputChange}
                className="form-control"
                rows={3}
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                onClick={onNext}
                className="btn btn-primary"
                disabled={isProcessing}
              >
                {isProcessing ? (
                  <div className="d-flex align-items-center">
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Processing...
                  </div>
                ) : (
                  'Review & Pay'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 