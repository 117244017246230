import React, { useState, useCallback } from 'react';
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { UserDetails } from './types';
import squareLogo from "../../assets/images/square-logo.png";
import { Button } from '@mui/material';
import Addons from './Addons';

interface PaymentProps {
  price: number | null;
  userInfo: UserDetails;
  selectedDate: Date;
  selectedTime: string;
  acceptedToc: boolean;
  addonsConfig: any;
  setAcceptedToc: (accepted: boolean) => void;
  onOpenTerms: () => void;
  bookingState: {
    paymentStatus: 'idle' | 'processing' | 'success' | 'error';
    error: string;
    isSuccess: boolean;
  };
  onPaymentSuccess: (token: any, buyer?: any, selectedAddons?: any) => Promise<void>;
  onPaymentError: (error: string) => void;
  partyType: string;
  partiesConfig: { [key: string]: { label: string; price: number } };
}

const cardStyle = {
  border: 'none',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px'
};

export const Payment: React.FC<PaymentProps> = ({
  price,
  userInfo,
  selectedDate,
  selectedTime,
  acceptedToc,
  setAcceptedToc,
  onOpenTerms,
  bookingState,
  onPaymentSuccess,
  onPaymentError,
  partyType,
  addonsConfig,
  partiesConfig,
}) => {
  const [addonPrice, setAddonPrice] = useState(0);
  const [hasCharacterSelected, setHasCharacterSelected] = useState(true);
  const [selectedAddons, setSelectedAddons] = useState<{ [key: string]: { enabled: boolean; options: { [key: number]: string | null } } }>({});

  const handleAddonPriceChange = useCallback((price: number) => {
    setAddonPrice(price);
  }, []);

  const handleCharacterSelectionChange = useCallback((selected: boolean) => {
    setHasCharacterSelected(selected);
  }, []);

  const handleSelectedAddonsChange = useCallback((addons: { [key: string]: { enabled: boolean; options: { [key: number]: string | null } } }) => {
    console.log('Updating selected addons:', addons);
    setSelectedAddons(prev => {
      const newState = { ...prev, ...addons };
      console.log('New selected addons state:', newState);
      return newState;
    });
  }, []);

  const totalAmount = price ? (price + addonPrice) : null;

  // Check if all enabled addons have their required options selected
  const areAddonsValid = useCallback(() => {
    const result = Object.entries(selectedAddons).every(([key, addon]) => {
      if (!addon.enabled) return true; // If not enabled, no validation needed

      // Get the addon config to check required options
      const addonConfig = addonsConfig?.[partyType]?.[key];
      if (!addonConfig) return true;

      // Check if all required options are selected
      const hasAllOptions = addonConfig.options.every((opt: { order: number; label: string }) => {
        const selectedValue = addon.options[opt.order];
        console.log(`Checking option ${opt.order} for ${key}:`, {
          required: opt.label,
          selected: selectedValue
        });
        return selectedValue !== null && selectedValue !== undefined;
      });

      console.log(`Addon ${key} validation:`, {
        enabled: addon.enabled,
        options: addon.options,
        hasAllOptions
      });
      return hasAllOptions;
    });
    console.log('Overall validation result:', result);
    return result;
  }, [selectedAddons, addonsConfig, partyType]);

  // Get the label of the first enabled addon
  const getEnabledAddonLabel = useCallback(() => {
    const enabledAddon = Object.entries(selectedAddons).find(([key, addon]) => {
      if (!addon.enabled) return false;
      return true; // Return the first enabled addon, regardless of validation
    });

    if (!enabledAddon) {
      console.log('No enabled addons');
      return '';
    }

    const [addonKey] = enabledAddon;
    const label = addonsConfig?.[partyType]?.[addonKey]?.label || addonKey;
    console.log('Found enabled addon:', label);
    return label;
  }, [selectedAddons, addonsConfig, partyType]);

  // Check if any addons are enabled
  const hasEnabledAddons = useCallback(() => {
    const result = Object.values(selectedAddons).some(addon => addon.enabled);
    return result;
  }, [selectedAddons]);

  if (bookingState.isSuccess && totalAmount != null) {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card p-4" style={cardStyle}>
              <h3>Booking Confirmed! 🎉</h3>
              <p>Thank you for booking with us. You will receive a confirmation email shortly.</p>
              <div className="booking-details">
                <p><strong>Date:</strong> {selectedDate?.toLocaleDateString()}</p>
                <p><strong>Time:</strong> {selectedTime}</p>
                <p><strong>Amount Paid:</strong> ${(totalAmount / 100).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        {/* Left side - Booking Summary */}
        <div className="col-md-6 mb-4 mb-md-0">
          <div className="card p-4" style={cardStyle}>
            <h3>Booking Summary</h3>
            <div className="mb-4">
              <h4>Personal Information</h4>
              <p className="mb-2"><strong>Name:</strong> {userInfo.name}</p>
              <p className="mb-2"><strong>Email:</strong> {userInfo.email}</p>
              <p className="mb-2"><strong>Phone:</strong> {userInfo.phone}</p>
              {userInfo.notes && (
                <p className="mb-2"><strong>Additional Notes:</strong> {userInfo.notes}</p>
              )}
            </div>
            <Addons
              addonsConfig={addonsConfig}
              partyType={partyType}
              onAddonPriceChange={handleAddonPriceChange}
              onCharacterSelectionChange={handleCharacterSelectionChange}
              onSelectedAddonsChange={handleSelectedAddonsChange}
            />

            <div>
              <h4>Booking Details</h4>
              <p className="mb-2"><strong>Party Type:</strong> {partiesConfig[partyType]?.label || partyType}</p>
              <p className="mb-2"><strong>Date:</strong> {selectedDate?.toLocaleDateString()}</p>
              <p className="mb-2"><strong>Time:</strong> {selectedTime}</p>
              <div className="d-flex justify-content-between border-top pt-3 mt-3">
                <span className="h5">Total Amount:</span>
                <span className="h5"> ${totalAmount ? (totalAmount / 100).toFixed(2) : '0.00'}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Right side - Payment Form */}
        <div className="col-md-6">
          <div className="card p-4" style={cardStyle}>
            <h3>Payment Details</h3>
            <div className="mb-4">
              <label className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={acceptedToc}
                  onChange={(e) => setAcceptedToc(e.target.checked)}
                  required
                  className="form-check-input"
                />
                <span>
                  I accept the{" "}
                  <button
                    onClick={onOpenTerms}
                    type="button"
                    className="btn btn-link p-0"
                  >
                    Terms and Conditions
                  </button>
                </span>
              </label>
            </div>

            <div className="position-relative">
              <PaymentForm
                applicationId={import.meta.env.VITE_SQUARE_APP_ID!}
                locationId={import.meta.env.VITE_SQUARE_LOCATION_ID!}
                cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
                  try {
                    await onPaymentSuccess(token, verifiedBuyer, selectedAddons);
                  } catch (error) {
                    onPaymentError(error instanceof Error ? error.message : 'Payment processing failed');
                  }
                }}
                createVerificationDetails={() => ({
                  amount: totalAmount ? (totalAmount / 100).toFixed(2) : '0.00',
                  billingContact: {
                    familyName: userInfo.name.split(" ").pop() || "",
                    givenName: userInfo.name.split(" ")[0] || "",
                    email: userInfo.email,
                    phone: userInfo.phone,
                  },
                  currencyCode: "USD",
                  intent: "CHARGE",
                })}
              >
                <CreditCard
                  buttonProps={{
                    isLoading: bookingState.paymentStatus === "processing",
                    css: {
                      width: '100%',
                      backgroundColor: "#8E2B8E",
                      fontSize: "16px",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#732373",
                      },
                    },
                  }}
                />
              </PaymentForm>
              {(!acceptedToc || (hasEnabledAddons() && !areAddonsValid())) && (
                <div
                  className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    zIndex: 1000
                  }}
                  onClick={() => {
                    const termsCheckbox = document.querySelector('.terms-label');
                    termsCheckbox?.classList.add('shake');
                    setTimeout(() => {
                      termsCheckbox?.classList.remove('shake');
                    }, 820);
                  }}
                >
                  <div className="text-center p-3"
                    style={{
                      backgroundColor: '#e9ecef',
                      padding: '1.5rem',
                      borderRadius: '8px',
                      maxWidth: '80%'
                    }}
                  >
                    {!acceptedToc && !hasEnabledAddons() && (
                      <div>
                        Please accept the Terms and Conditions to proceed
                      </div>
                    )}
                    {!acceptedToc && hasEnabledAddons() && (
                      <div>
                        Please accept the Terms and Conditions and complete all required fields in the {getEnabledAddonLabel()} to proceed
                      </div>
                    )}
                    {acceptedToc && hasEnabledAddons() && !areAddonsValid() && (
                      <div>
                        Please complete all required fields in the {getEnabledAddonLabel()} to proceed
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-center gap-2 mt-4">
              <img
                src={squareLogo}
                alt="Powered by Square"
                style={{ height: '24px' }}
              />
              <span className="text-muted">Secure payments powered by Square</span>
            </div>
          </div>
        </div>
      </div>

      {bookingState.error && (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger d-flex align-items-center mt-4" role="alert" style={{ border: 'none' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24" className="me-2">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
              </svg>
              <span>{bookingState.error}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}; 