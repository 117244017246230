import React, { useState, useEffect, useCallback, memo } from 'react';
import {
	FormControlLabel,
	Switch,
	Typography,
	Box,
	Collapse,
	Paper,
	Stack
} from '@mui/material';

interface OptionWithPrice {
	label: string;
	price: number;
	descripiton: string[];
}

interface AddonOption {
	order: number;
	options: OptionWithPrice[] | string[];
	label: string;
}

interface AddonConfig {
	options: AddonOption[];
	label: string;
}

interface PartyTypeConfig {
	[key: string]: AddonConfig;
}

interface AddonsConfig {
	[key: string]: PartyTypeConfig;
}

interface AddonsProps {
	addonsConfig: AddonsConfig | null;
	partyType?: string;
	onAddonPriceChange?: (price: number) => void;
	onCharacterSelectionChange?: (hasCharacterSelected: boolean) => void;
	onSelectedAddonsChange?: (selectedAddons: { [key: string]: { enabled: boolean; options: { [key: number]: string | null } } }) => void;
}

const Addons = memo<AddonsProps>(({ addonsConfig, partyType, onAddonPriceChange, onCharacterSelectionChange, onSelectedAddonsChange }) => {
	const [enabledAddons, setEnabledAddons] = useState<{ [key: string]: boolean }>({});
	const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: { [key: number]: string | null } }>({});
	const [isInitialized, setIsInitialized] = useState(false);

	if (!addonsConfig || !partyType) {
		return null;
	}

	// Get the addon config for the current party type
	const partyConfig = addonsConfig[partyType];
	if (!partyConfig) {
		return null;
	}

	// Initialize states only once when component mounts or partyType changes
	useEffect(() => {
		if (!isInitialized) {
			const initialEnabledState = Object.keys(partyConfig).reduce((acc, key) => {
				acc[key] = false;
				return acc;
			}, {} as { [key: string]: boolean });
			setEnabledAddons(initialEnabledState);
			setSelectedOptions({});
			setIsInitialized(true);
		}
	}, [partyType, partyConfig, isInitialized]);

	// Combined effect for price calculation, validation, and parent notification
	useEffect(() => {
		// Calculate total price
		let totalPrice = 0;
		Object.entries(enabledAddons).forEach(([addonKey, isEnabled]) => {
			if (isEnabled && selectedOptions[addonKey]) {
				const addonConfig = partyConfig[addonKey];
				addonConfig.options.forEach(opt => {
					if (Array.isArray(opt.options) && opt.options[0] && typeof opt.options[0] === 'object') {
						const priceOptions = opt.options as OptionWithPrice[];
						const selectedOption = selectedOptions[addonKey][opt.order];
						const option = priceOptions.find(opt => opt.label === selectedOption);
						totalPrice += option?.price || 0;
					}
				});
			}
		});

		// Check validation
		const hasAllRequiredFields = Object.entries(enabledAddons).every(([addonKey, isEnabled]) => {
			if (!isEnabled) return true;
			const addonConfig = partyConfig[addonKey];
			return addonConfig.options.every(opt => selectedOptions[addonKey]?.[opt.order]);
		});

		// Prepare selected addons for parent
		const selectedAddons = Object.keys(partyConfig).reduce((acc, key) => {
			acc[key] = {
				enabled: enabledAddons[key] || false,
				options: selectedOptions[key] || {}
			};
			return acc;
		}, {} as { [key: string]: { enabled: boolean; options: { [key: number]: string | null } } });

		// Notify parent of changes
		onAddonPriceChange?.(totalPrice);
		onCharacterSelectionChange?.(hasAllRequiredFields);
		onSelectedAddonsChange?.(selectedAddons);
	}, [enabledAddons, selectedOptions, partyConfig, onAddonPriceChange, onCharacterSelectionChange, onSelectedAddonsChange]);

	const handleAddonToggle = useCallback((addonKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const newEnabledState = event.target.checked;
		setEnabledAddons(prev => ({
			...prev,
			[addonKey]: newEnabledState
		}));

		if (!newEnabledState) {
			setSelectedOptions(prev => ({ ...prev, [addonKey]: {} }));
		}
	}, []);

	const handleOptionSelect = useCallback((addonKey: string, optionOrder: number, optionLabel: string) => {
		setSelectedOptions(prev => ({
			...prev,
			[addonKey]: {
				...prev[addonKey],
				[optionOrder]: optionLabel
			}
		}));
	}, []);

	const renderOption = (addonKey: string, option: AddonOption) => {
		const isStringArray = Array.isArray(option.options) && option.options.length > 0 && typeof option.options[0] === 'string';
		const selectedValue = selectedOptions[addonKey]?.[option.order];

		return (
			<Box key={option.label}>
				<Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
					{option.label} <Typography component="span" color="error">*</Typography>
				</Typography>
				<Stack spacing={1}>
					{option.options.map((opt) => {
						if (isStringArray) {
							const stringOption = opt as string;
							return (
								<Paper
									key={stringOption}
									elevation={0}
									sx={{
										cursor: 'pointer',
										transition: 'all 0.2s ease',
										border: '1px solid',
										borderColor: selectedValue === stringOption ? 'primary.main' : 'divider',
										bgcolor: selectedValue === stringOption ? 'action.selected' : 'transparent',
										'&:hover': {
											borderColor: 'primary.main',
											bgcolor: 'action.hover',
											transform: 'translateY(-1px)',
											boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
										}
									}}
									onClick={() => handleOptionSelect(addonKey, option.order, stringOption)}
								>
									<Box sx={{ p: 1.5 }}>
										<Typography
											variant="body1"
											sx={{
												fontWeight: selectedValue === stringOption ? 500 : 400,
												color: selectedValue === stringOption ? 'primary.main' : 'text.primary'
											}}
										>
											{stringOption}
										</Typography>
									</Box>
								</Paper>
							);
						} else {
							const priceOption = opt as OptionWithPrice;
							return (
								<Paper
									key={priceOption.label}
									elevation={0}
									sx={{
										cursor: 'pointer',
										transition: 'all 0.2s ease',
										border: '1px solid',
										borderColor: selectedValue === priceOption.label ? 'primary.main' : 'divider',
										bgcolor: selectedValue === priceOption.label ? 'action.selected' : 'transparent',
										'&:hover': {
											borderColor: 'primary.main',
											bgcolor: 'action.hover',
											transform: 'translateY(-1px)',
											boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
										}
									}}
									onClick={() => handleOptionSelect(addonKey, option.order, priceOption.label)}
								>
									<Box sx={{ p: 1.5 }}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
											<Box>
												<Typography variant="body1" sx={{ fontWeight: selectedValue === priceOption.label ? 500 : 400 }}>
													{priceOption.label}
												</Typography>
												<Stack spacing={0.5} sx={{ mt: 0.5 }}>
													{priceOption.descripiton.map((activity, index) => (
														<Typography
															key={index}
															variant="body2"
															color="text.secondary"
															sx={{
																transition: 'all 0.2s ease',
																opacity: selectedValue === priceOption.label ? 1 : 0.7,
																fontSize: '0.875rem'
															}}
														>
															• {activity}
														</Typography>
													))}
												</Stack>
											</Box>
											<Typography
												variant="body1"
												sx={{
													ml: 2,
													fontWeight: selectedValue === priceOption.label ? 500 : 400,
													color: selectedValue === priceOption.label ? 'primary.main' : 'text.secondary'
												}}
											>
												${(priceOption.price / 100).toFixed(2)}
											</Typography>
										</Box>
									</Box>
								</Paper>
							);
						}
					})}
				</Stack>
			</Box>
		);
	};

	return (
		<Stack spacing={2}>
			{Object.entries(partyConfig).map(([addonKey, addonConfig]) => {
				const isEnabled = enabledAddons[addonKey] || false;

				return (
					<Paper
						key={addonKey}
						elevation={0}
						className="addons-section"
						sx={{
							p: 2,
							mb: 2,
							border: '1px solid',
							borderColor: 'divider',
							borderRadius: 2,
							bgcolor: 'background.paper'
						}}
					>
						<Stack spacing={2}>
							<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<Typography variant="h6" sx={{ fontWeight: 500 }}>
									{addonConfig.label}
								</Typography>
								<FormControlLabel
									control={
										<Switch
											checked={isEnabled}
											onChange={handleAddonToggle(addonKey)}
											color="primary"
										/>
									}
									label="Include"
									sx={{ m: 0 }}
								/>
							</Box>

							<Collapse in={isEnabled} timeout={500}>
								<Stack spacing={2}>
									{addonConfig.options.map(option => renderOption(addonKey, option))}
								</Stack>
							</Collapse>
						</Stack>
					</Paper>
				);
			})}
		</Stack>
	);
});

export default Addons;
